<template>
  <div class="sm:mx-auto sm:w-full sm:max-w-md">
    <div class="px-4 py-8 shadow sm:rounded-lg sm:px-10">
      <div>
        <div class="relative">
          <div class="absolute inset-0 flex items-center">
            <div class="w-full border-t border-gray-300" />
          </div>
          <div class="relative flex justify-center text-sm">
            <span class="bg-white px-2 text-gray-500 dark:bg-stone-900">
              By LUKSO Team 💜
            </span>
          </div>
        </div>
        <slot />
      </div>
    </div>
  </div>
</template>
